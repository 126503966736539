import React, { Fragment } from 'react';
import CurrencyInput from 'react-currency-input';

function InputRs(props) {
  return (
    <Fragment>
      <CurrencyInput
        {...props}
        style={{ minWidth: '100px', width: '100%' }}
        prefix="R$"
        maxLength={10}
        className="form-control"
        decimalSeparator=","
        thousandSeparator="."
      />
    </Fragment>
  );
}

export default InputRs;
