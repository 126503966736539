/*
error: parametro obrigatório é o error response da API
message: Parametro opcional, um array de objetos para trocar a mensagem
de um erro code específico ou varios, caso não queira utilizar o erro default
ignore: Use a prop ignore para forçar o erro retornado da API

exemplo de uso da chamada desse método com o message:

const message = [
  {
    code: 404,
    message: "Nova mensagem para o erro 404"
  },
  {
    code: "default",
    message: "Nova mensagem para o default error"
  },
  {
    code: "400",
    ignore: true,   //Retornará a mensagem de erro que vem da API para o erro 400
  },
]
CatchErrors(error, message)  //Retornará uma string com a mensagem do erro

*/

function CatchErrors(error, msg) {
  console.error(error);

  let cError = error;

  if (error.code) {
    const status = parseInt(error.code, 10);
    const response = { status };
    cError = { response };
  }

  let message400 = 'Registro não encontrado';
  let message401 = 'Você não possui autorização para realizar esta ação';
  let message404 = 'Recurso não encontrado';
  let message11012 = 'Nome inválido';
  let messageDefault = 'Erro de conexão com o servidor';

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    error.response.data.message.error
  ) {
    return error.response.data.message.error;
  }

  if (msg) {
    for (let i = 0; i < msg.length; i++) {
      switch (msg[i].code) {
        case 400:
          if (msg[i].ignore) {
            if (cError && cError.response && cError.response.status) {
              cError.response.status = null;
            }
            break;
          }
          message400 = msg[i].message;
          break;

        case 401:
          if (msg[i].ignore) {
            if (cError && cError.response && cError.response.status) {
              cError.response.status = null;
            }
            break;
          }
          message401 = msg[i].message;

          break;

        case 404:
          if (msg[i].ignore) {
            if (cError && cError.response && cError.response.status) {
              cError.response.status = null;
            }
            break;
          }
          message404 = msg[i].message;
          break;

        case 11012:
          if (msg[i].ignore) {
            if (cError && cError.response && cError.response.status) {
              cError.response.status = null;
            }
            cError.response.status = null;
            break;
          }
          message11012 = msg[i].message;
          break;

        default:
          messageDefault = msg[i].message;
          break;
      }
    }
  }

  if (cError.response) {
    switch (cError.response.status) {
      case 400:
        return message400;

      case 401:
        return message401;

      case 404:
        return message404;

      case 11012:
        return message11012;

      default:
        if (cError.response && cError.response.data) {
          if (cError.response.data.error) {
          }

          if (
            cError.response.data.error &&
            cError.response.data.error.message
          ) {
            return cError.response.data.error.message;
          }

          if (cError.response.data.error) {
            return cError.response.data.error;
          }

          if (
            cError.response.data.message &&
            cError.response.data.message.error
          ) {
            return cError.response.data.message.error;
          }

          return messageDefault;
        }
    }
  } else {
    return messageDefault;
  }
}

export default CatchErrors;
