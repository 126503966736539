import React, { Fragment, useState } from 'react';
import { Input, Row, Col, Modal } from 'antd';
import { Button } from 'reactstrap';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AddValue from './add';

function AddCredit(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Fragment>
      <Row>
        <Modal
          visible={isModalVisible}
          footer={false}
          closable={false}
          style={{ padding: 0 }}
        >
          <AddValue setIsModalVisible={setIsModalVisible} />
        </Modal>

        <Button
          onClick={() => setIsModalVisible(true)}
          className="button-add-credit"
          color="succes"
          {...props}
          size="large"
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <DollarCircleOutlined
              style={{
                paddingRight: '6px',
                alignSelf: 'center',
                fontSize: '16px',
              }}
            />
            {props.children}
          </div>
        </Button>
      </Row>
    </Fragment>
  );
}

export default AddCredit;
