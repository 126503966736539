// ** Icons Import
import { Package, Repeat, BookOpen } from 'react-feather';

export default [
  {
    id: 'fulfillment',
    title: 'Fulfillment',
    icon: <Package />,
    children: [
      {
        id: 'entradasaidamercadoria',
        title: 'Entrada / Saida de mercadoria',
        icon: <Repeat />,
        navLink: '/admin/fulfillment/goods',
      },
      {
        id: 'movimentacaoestoque',
        title: 'Movimentação de estoque',
        icon: <BookOpen />,
        navLink: '/admin/fulfillment/stock',
      },
      {
        id: 'embalagens',
        title: 'Embalagens',
        icon: <Package />,
        navLink: '/admin/fulfillment/packaging',
      },
      {
        id: 'embalagensmodelo',
        title: 'Modelos de embalagem',
        icon: <Package />,
        navLink: '/admin/fulfillment/packaging-model',
      },
      {
        id: 'embalagenstipo',
        title: 'Tipos de embalagem',
        icon: <Package />,
        navLink: '/admin/fulfillment/packaging-type',
      },
    ],
  },
];
