import axios from 'axios';
import { getToken } from './auth';

const baseURL = process.env.REACT_APP_API_URI || 'http://localhost:3333/';
// const currentToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkNjFjYjhhYmYxMTJlNTU0MDIxMWY2OSIsImlhdCI6MTU3MDIyNTY2OX0.qF2dOLN8nC6lB290LKtH-6sZuLl4DV8V-ru1pMvrq9w";

const api = axios.create({ baseURL });

// api.defaults.headers.common = {'Authorization': `Bearer ${currentToken}`}
api.interceptors.request.use(async (config) => {
  let token = getToken();
  token = JSON.parse(token);

  if (token && token.token) {
    token = JSON.parse(token.token);
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
