import mock from '../mock';

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'dashboardAdmin',
        // isBookmarked: false,
        title: 'Dashboard',
        icon: 'Home',
        link: '/admin/dashboard',
      },
      {
        id: 2,
        target: 'calculadoraFrete',
        // isBookmarked: false,
        title: 'Calculadora de Frete',
        icon: 'DivideCircle',
        link: '/admin/calculator/shipping',
      },
      {
        id: 3,
        target: 'cadastroComissão',
        // isBookmarked: true,
        title: 'Cadastro de Comissão',
        icon: 'DollarSign',
        link: '/admin/administration/commission',
      },
      {
        id: 4,
        target: 'usuarios',
        // isBookmarked: true,
        title: 'Usuários',
        icon: 'UserPlus',
        link: '/admin/administration/users',
      },
      {
        id: 5,
        target: 'transportadoras',
        // isBookmarked: true,
        title: 'Transportadoras',
        icon: 'Truck',
        link: '/admin/registration/carriers',
      },
      {
        id: 6,
        target: 'clientes',
        // isBookmarked: true,
        title: 'Clientes',
        icon: 'Users',
        link: '/admin/registration/customers',
      },
      {
        id: 7,
        target: 'representantes',
        // isBookmarked: false,
        title: 'Representantes',
        icon: 'Users',
        link: '/admin/registration/representante',
      },
      {
        id: 8,
        target: 'listaFaturamento',
        // isBookmarked: false,
        title: 'Lista de Faturamento',
        icon: 'ArrowDownCircle',
        link: '/admin/financial/billingwriteoff',
      },
      {
        id: 9,
        target: 'carteiraCliente',
        // isBookmarked: false,
        title: 'Carteira do Cliente',
        icon: 'Book',
        link: '/admin/financial/clientwallet',
      },
      {
        id: 10,
        target: 'boletos',
        // isBookmarked: false,
        title: 'Boletos',
        icon: 'Printer',
        link: '/admin/financial/boleto',
      },
      {
        id: 11,
        target: 'envioXmlMercadorias',
        // isBookmarked: false,
        title: 'Envio de XML de Mercadorias',
        icon: 'Paperclip',
        link: '/admin/ExportXML',
      },
      {
        id: 12,
        target: 'chamados',
        // isBookmarked: false,
        title: 'Chamados',
        icon: 'Phone',
        link: '/admin/help/servicecall',
      },
      {
        id: 13,
        target: 'entradaMercadoria',
        // isBookmarked: false,
        title: 'Entrada de Mercadoria',
        icon: 'Edit2',
        link: '/admin/collect/goodsreceipt',
      },
      {
        id: 14,
        target: 'listaRetirada',
        // isBookmarked: false,
        title: 'Lista de Retirada',
        icon: 'ExternalLink',
        link: '/admin/collect/withdrawlist',
      },
      {
        id: 15,
        target: 'retiradaTransportadora',
        // isBookmarked: false,
        title: 'Manifesto',
        icon: 'Truck',
        link: '/admin/collect/withdrawlistcarrier',
      },
      {
        id: 16,
        target: 'códigoRastreio',
        // isBookmarked: false,
        title: 'Código de Rastreio',
        icon: 'Search',
        link: '/admin/collect/trackingcode',
      },
    ],
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Passport Image',
        by: 'Oliver Queen',
        size: '52kb',
        file: require('@src/assets/images/icons/jpg.png').default,
      },
      {
        title: 'Parenting Guide',
        by: 'Alfred Pennyworth',
        size: '2.3mb',
        file: require('@src/assets/images/icons/doc.png').default,
      },
      {
        title: 'Class Notes',
        by: 'Barry Allen',
        size: '30kb',
        file: require('@src/assets/images/icons/doc.png').default,
      },
      {
        title: 'Class Attendance',
        by: 'Walter White',
        size: '52mb',
        file: require('@src/assets/images/icons/xls.png').default,
      },
    ],
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg')
          .default,
        date: '01/03/2020',
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg')
          .default,
        date: '07/03/2020',
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg')
          .default,
        date: '14/03/2020',
      },
      {
        title: 'Robert Nash',
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg')
          .default,
        date: '21/03/2020',
      },
    ],
  },
];

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }];
});

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked);
  const suggestions = searchArr[0].data;
  return [200, { suggestions, bookmarks }];
});

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply((config) => {
  const { id } = JSON.parse(config.data);

  const obj = searchArr[0].data.find((item) => item.id === id);

  Object.assign(obj, { isBookmarked: !obj.isBookmarked });

  return [200];
});
