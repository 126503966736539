import { UserList } from '@src/components/UserList';

export const checkUserType = (user) => {
  const userList = UserList();

  for (let i = 0; i < userList.length; i += 1) {
    if (parseInt(user.user_type, 10) === userList[i].value) {
      return userList[i].type;
    }
  }

  return null;
};

export const userIsActivated = (user) => {
  switch (checkUserType(user)) {
    case 'client':
      if (user.is_active && user.customer && user.customer.is_active) {
        return true;
      }
      return false;

    case 'admin':
      if (user.is_active) {
        return true;
      }

      return false;

    default:
      return false;
  }
};
