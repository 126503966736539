// ** Icons Import
import { Edit, ArrowDownCircle, Book, Printer, DollarSign } from 'react-feather';
import {FaCashRegister} from 'react-icons/fa'
import {TbReportMoney} from 'react-icons/tb'

export default [
  {
    id: 'financeiro',
    title: 'Financeiro',
    icon: <Edit />,
    children: [
      {
        id: 'listaFaturamento',
        title: 'Lista de Faturamento',
        icon: <ArrowDownCircle />,
        navLink: '/admin/financial/billingwriteoff',
      },
      {
        id: 'carteiraCliente',
        title: 'Carteira do Cliente',
        icon: <Book />,
        navLink: '/admin/financial/clientwallet',
      },
      {
        id: 'boletos',
        title: 'Boletos',
        icon: <Printer />,
        navLink: '/admin/financial/boleto',
      },
      {
        id: 'financialReports',
        title: 'Relatórios Financeiro',
        icon: <TbReportMoney />,
        navLink: '/admin/financial/financial-reports',
      },
      {
        id: 'automaticBilling',
        title: 'Faturamento Automático',
        icon: <FaCashRegister />,
        navLink: '/admin/financial/automatic-billing',
      },

    ],
  },
];
