// ** Icons Import
import { Home, Archive, DivideCircle } from 'react-feather'

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Home />,
    children: [
      {
        id: 'dashboardAdmin',
        title: 'Dashboard',
        icon: <Archive />,
        navLink: '/admin/dashboard'
      },
      {
        id: 'calculadoraFrete',
        title: 'Calculadora de Frete',
        icon: <DivideCircle />,
        navLink: '/admin/calculator/shipping'
      }
    ]
  }
]
