import React, { useEffect, useState, Fragment } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoadingPage from '@src/components/LoadingPage';
import api from '@src/services/envieja-api';
import {
  checkUserType,
  userIsActivated,
} from '@src/components/UserIsActivated';

function PrivateRoute({ component: Component, ...rest }) {
  let attempt = 0;

  const [loading, setLoading] = useState(true);
  const [permited, setPermited] = useState(false);

  const token = useSelector((state) => state.otimize.token);
  const usertype = useSelector((state) => state.otimize.usertype);
  const userterms = useSelector((state) => state.otimize.userterms);
  const usercustomer = useSelector((state) => state.otimize.usercustomer);
  const useractive = useSelector((state) => state.otimize.useractive);

  const { permission } = { ...rest };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (!usertype) {
        getSession();
      } else {
        UserPermited();
      }
    }, 1000);
  }, [usertype]);

  const clearSession = () => {
    dispatch({
      type: 'SET_SESSION',
      userid: null,
      username: null,
      useremail: null,
      usertype: null,
      useractive: null,
      usercustomerid: null,
      userterms: null,
      usercustomer: null,
    });

    dispatch({
      type: 'SET_TOKEN',
      token: null,
    });
  };

  const setSession = (user) => {
    dispatch({
      type: 'SET_SESSION',
      userid: user.id,
      username: user.name,
      userlastname: user.last_name,
      useremail: user.email,
      usertype: user.user_type,
      useractive: user.is_active,
      usercustomerid: user.customer_id,
      userterms: user.is_ok_terms,
      usercustomer: user.customer,
    });
  };

  const getSession = async () => {
    await api
      .get('get-user-session')
      .then((response) => {
        if (response.status === 204) {
          checkAttempt();
        } else {
          const user = response.data;

          setSession(user);
        }
      })
      .catch((error) => {
        checkAttempt();
      });
  };

  function checkAttempt() {
    setTimeout(() => {
      attempt += 1;

      if (attempt > 3) {
        clearSession();
        history.push('/login');
      } else {
        getSession();
      }
    }, 1000);
  }

  function checkUserActivated(user) {
    if (userIsActivated(user)) {
      return true;
    }

    clearSession();
    return false;
  }

  const UserPermited = () => {
    const user = {
      user_type: usertype,
      is_active: useractive,
      customer: usercustomer,
    };

    const userType = checkUserType(user);

    if (
      permission &&
      checkUserActivated(user) &&
      (userterms || userType === 'admin')
    ) {
      for (let i = 0; i < permission.length; i += 1) {
        if (usertype === permission[i].toString()) {
          setPermited(true);
          setLoading(false);

          return;
        }
      }
      setPermited(false);
      setLoading(false);
    } else {
      setPermited(false);
      setLoading(false);
    }
  };

  if (loading) {
    console.log(loading);

    return (
      <Fragment>
        <LoadingPage />
      </Fragment>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          permited ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;
