// ** Icons Import
import { Briefcase, User, Link } from 'react-feather';

export default [
  {
    id: 'cadastroClient',
    title: 'Cadastro',
    icon: <User />,
    children: [
      {
        id: 'usuárioClient',
        title: 'Usuários',
        icon: <User />,

        navLink: '/users',
      },
      {
        id: 'empresaClient',
        title: 'Dados da empresa',
        icon: <Briefcase />,

        navLink: '/company',
      },
    ],
  },
];
