// ** Icons Import
import { Home, Archive } from 'react-feather';

export default [
  {
    id: 'dashboardClient',
    title: 'Dashboard',
    icon: <Home />,
    children: [
      {
        id: 'dashboardClient',
        title: 'Dashboard',
        icon: <Archive />,
        navLink: '/dashboard',
      },
    ],
  },
];
