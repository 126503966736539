export const stringDolarToStringReal = (stringDolar) => {

  if (stringDolar) {

    let stringReal = parseFloat(stringDolar, 10)


    stringReal = stringReal.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })


    return stringReal;
  }
  console.error('stringDolarToStringReal - Valor nulo');
};
