import { combineReducers } from 'redux';

import { persistStore } from 'redux-persist';
import persistReducerConfig from '../config/persistReducerConfig';
import reducer from '../reducers/index';
import navbar from './navbar';
import layout from './layout';
import todo from '@src/views/apps/todo/store';
import chat from '@src/views/apps/chat/store';
import users from '@src/views/apps/user/store';
import email from '@src/views/apps/email/store';
import invoice from '@src/views/apps/invoice/store';
import calendar from '@src/views/apps/calendar/store';
import ecommerce from '@src/views/apps/ecommerce/store';
import dataTables from '@src/views/tables/data-tables/store';
import permissions from '@src/views/apps/roles-permissions/store';
import { configureStore } from '@reduxjs/toolkit';

const otimize = persistReducerConfig(reducer);

const reducers = combineReducers({
  otimize,
  layout,
  ecommerce,
  navbar,
  todo,

  chat,
  users,
  email,
  invoice,
  calendar,
  dataTables,
  permissions,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
  },
});
const persistor = persistStore(store);

export { store, persistor };
