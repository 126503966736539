export const stringRealToFloatDolar = (stringReal) => {
  let dolar;

  if (stringReal) {
    dolar = stringReal;
    dolar = dolar.replace('R$', '')
    dolar = dolar.replace('.', '');
    dolar = dolar.replace(',', '.');
    dolar = parseFloat(dolar);

    return dolar;
  }
  console.error('stringRealToFloatDolar - Valor nulo');
};
