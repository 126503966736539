export const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    email: '${label} não é válido!',
    number: '${label} não é um número válido!',
  },
  password: {
    min: 'Teste',
  },

  number: {
    range: '${label} deve ter entre ${min} e ${max} caractéres',
  },
};
