// ** React Imports
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const AppRoutes = [
  {
    path: '/dashboard',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Client/Dashboard')),
  },

  {
    path: '/send-goods',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Client/Operations/SendGoods')),
  },

  {
    path: '/shipments',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Client/Operations/Shipments')),
  },

  {
    path: '/shipments/:id',
    exact: true,
    appLayout: false,

    component: lazy(() =>
      import('../../pages/Client/Operations/Shipments/Label')
    ),
  },

  {
    path: '/operations/tracking',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Operations/Tracking')),
  },

  {
    path: '/collect/tracking',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Operations/Tracking')),
  },

  {
    path: '/financial/invoices',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Financial/Invoices')),
  },

  {
    path: '/boletoClient',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Boleto')),
  },

  {
    path: '/boletoClient',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Boleto')),
  },

  {
    path: '/servicecall/add',
    exact: true,
    appLayout: false,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Client/Help/ServiceCall/Form')),
  },

  {
    path: '/servicecall/:id',
    exact: true,
    appLayout: false,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Client/Help/ServiceCall/Show')),
  },

  {
    path: '/users',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Register/Users')),
  },
  {
    path: '/users/add',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Register/Users/Form')),
  },
  {
    path: '/users/:id',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Register/Users/Form')),
  },
  {
    path: '/company',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Register/Company')),
  },

  {
    path: '/importXML',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/ImportXML')),
  },

  {
    path: '/serviceCall',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Help/ServiceCall')),
  },

  {
    path: '/operations/myorders',
    exact: true,
    appLayout: false,
    component: lazy(() => import('../../pages/Client/Operations/MyOrders')),
  },

  {
    path: '/admin/dashboard',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Admin/Dashboard')),
  },

  {
    path: '/admin/dashboard',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Admin/Dashboard')),
  },
  {
    path: '/admin/calculator/shipping',
    exact: true,
    appLayout: false,

    component: lazy(() => import('../../pages/Admin/Calculator/Shipping')),
  },

  {
    path: '/admin/administration/update-freight',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/UpdateFreightTables')
    ),
  },

  {
    path: '/admin/administration/update-freight/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/UpdateFreightTables/ViewTable')
    ),
  },

  {
    path: '/admin/administration/whatsapp-logs',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/WhatsappLogs')
    ),
  },

  {
    path: '/admin/administration/commission',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Administration/Comission')),
  },
  {
    path: '/admin/administration/users',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Administration/Users')),
  },
  {
    path: '/admin/administration/users/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/Users/Form')
    ),
  },
  {
    path: '/admin/administration/users/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/Users/Form')
    ),
  },

  {
    path: '/admin/registration/carriers/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Carriers/Form')
    ),
  },
  {
    path: '/admin/registration/carriers/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Carriers/Form')
    ),
  },
  {
    path: '/admin/administration/users/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Administration/Users/Form')
    ),
  },
  {
    path: '/admin/registration/carriers',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Registration/Carriers')),
  },

  {
    path: '/admin/registration/customers',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Registration/Customers')),
  },
  {
    path: '/admin/registration/customers/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Customers/Form')
    ),
  },
  {
    path: '/admin/registration/customers/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Customers/Form')
    ),
  },
  {
    path: '/admin/registration/representante',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Representante')
    ),
  },
  {
    path: '/admin/registration/representante/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Representante/Form')
    ),
  },
  {
    path: '/admin/registration/representante/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Representante/Form')
    ),
  },
  {
    path: '/admin/financial/billingwriteoff',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Financial/BillingWriteoff')
    ),
  },
  {
    path: '/admin/financial/clientwallet',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Financial/ClientWallet')),
  },
  {
    path: '/admin/financial/boleto',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Financial/Boleto')),
  },
  {
    path: '/admin/financial/boleto/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Financial/Boleto/Form')),
  },
  {
    path: '/admin/financial/boleto/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Financial/Boleto/Form')),
  },
  {
    path: '/admin/financial/financial-reports',
    exact: true,
    appLayout: true,
    component: lazy(() =>
      import('../../pages/Admin/Financial/FinancialReportByCustomer')
    ),
  },
  {
    path: '/admin/financial/automatic-billing',
    exact: true,
    appLayout: true,
    component: lazy(() =>
      import('../../pages/Admin/Financial/AutomaticBilling')
    ),
  },

  {
    path: '/admin/ExportXML',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/ExportXML')),
  },
  {
    path: '/admin/importXML',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/ImportXmlByAUser')),
  },
  {
    path: '/admin/help/servicecall',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Help/ServiceCall')),
  },
  {
    path: '/admin/help/servicecall/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Help/ServiceCall/Form')),
  },
  {
    path: '/admin/help/servicecall/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Help/ServiceCall/Show')),
  },

  {
    path: '/admin/collect/goodsreceipt',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Collect/GoodsReceipt')),
  },

  {
    path: '/admin/collect/goodsreceipt/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/GoodsReceipt/Show')
    ),
  },

  {
    path: '/admin/collect/goodsreceipt/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/GoodsReceipt/Show')
    ),
  },

  {
    path: '/admin/collect/trackingcode',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Collect/TrackingCode')),
  },
  {
    path: '/admin/collect/withdrawlist',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Collect/WithdrawList')),
  },
  {
    path: '/admin/collect/withdrawlist/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/WithdrawList/Show')
    ),
  },

  {
    path: '/admin/collect/withdrawlistcarrier',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/WithdrawListCarrier')
    ),
  },

  {
    path: '/admin/collect/withdrawlistcarrier/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/WithdrawListCarrier/Show')
    ),
  },

  {
    path: '/admin/collect/withdrawlistcarrier/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Collect/WithdrawListCarrier/Show')
    ),
  },

  {
    path: '/admin/registration/customers/edit/user/:id/:customerId',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Customers/FormUser')
    ),
  },

  {
    path: '/admin/registration/customers/add/user/:customerId',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/Customers/FormUser')
    ),
  },

  {
    path: '/admin/registration/cities',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Registration/Cities')),
  },

  {
    path: '/admin/registration/cities/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Registration/Cities/Form')),
  },

  {
    path: '/admin/registration/cities/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Registration/Cities/Form')),
  },

  {
    path: '/admin/registration/mappingstatus',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/MappingStatus/Form')
    ),
  },

  {
    path: '/apps/email',
    exact: true,
    appLayout: true,
    className: 'email-application',
    component: lazy(() => import('../../views/apps/email')),
  },
  {
    path: '/apps/email/:folder',
    exact: true,
    appLayout: true,
    className: 'email-application',
    component: lazy(() => import('../../views/apps/email')),
    meta: {
      navLink: '/apps/email',
    },
  },
  {
    path: '/apps/email/label/:label',
    exact: true,
    appLayout: true,
    className: 'email-application',
    component: lazy(() => import('../../views/apps/email')),
    meta: {
      navLink: '/apps/email',
    },
  },
  {
    path: '/apps/email/:filter',
    component: lazy(() => import('../../views/apps/email')),
    meta: {
      navLink: '/apps/email',
    },
  },
  {
    path: '/apps/chat',
    appLayout: true,
    className: 'chat-application',
    component: lazy(() => import('../../views/apps/chat')),
  },
  {
    path: '/apps/todo',
    exact: true,
    appLayout: true,
    className: 'todo-application',
    component: lazy(() => import('../../views/apps/todo')),
  },
  {
    path: '/apps/todo/:filter',
    appLayout: true,
    exact: true,
    className: 'todo-application',
    component: lazy(() => import('../../views/apps/todo')),
    meta: {
      navLink: '/apps/todo',
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    appLayout: true,
    className: 'todo-application',
    component: lazy(() => import('../../views/apps/todo')),
    meta: {
      navLink: '/apps/todo',
    },
  },
  {
    path: '/apps/calendar',
    component: lazy(() => import('../../views/apps/calendar')),
  },
  {
    path: '/apps/invoice/list',
    component: lazy(() => import('../../views/apps/invoice/list')),
  },
  {
    path: '/apps/invoice/preview/:id',
    component: lazy(() => import('../../views/apps/invoice/preview')),
    meta: {
      navLink: '/apps/invoice/preview',
    },
  },
  {
    path: '/apps/invoice/preview',
    exact: true,
    component: () => <Redirect to="/apps/invoice/preview/4987" />,
  },
  {
    path: '/apps/invoice/edit/:id',
    component: lazy(() => import('../../views/apps/invoice/edit')),
    meta: {
      navLink: '/apps/invoice/edit',
    },
  },
  {
    path: '/apps/invoice/edit',
    exact: true,
    component: () => <Redirect to="/apps/invoice/edit/4987" />,
  },
  {
    path: '/apps/invoice/add',
    component: lazy(() => import('../../views/apps/invoice/add')),
  },
  {
    path: '/apps/invoice/print',
    layout: 'BlankLayout',
    component: lazy(() => import('../../views/apps/invoice/print')),
  },
  {
    path: '/apps/ecommerce/shop',
    className: 'ecommerce-application',
    component: lazy(() => import('../../views/apps/ecommerce/shop')),
  },
  {
    path: '/apps/ecommerce/wishlist',
    className: 'ecommerce-application',
    component: lazy(() => import('../../views/apps/ecommerce/wishlist')),
  },
  {
    path: '/apps/ecommerce/product-detail',
    exact: true,
    className: 'ecommerce-application',
    component: () => (
      <Redirect to="/apps/ecommerce/product-detail/apple-i-phone-11-64-gb-black-26" />
    ),
  },
  {
    path: '/apps/ecommerce/product-detail/:product',
    exact: true,
    className: 'ecommerce-application',
    component: lazy(() => import('../../views/apps/ecommerce/detail')),
    meta: {
      navLink: '/apps/ecommerce/product-detail',
    },
  },
  {
    path: '/apps/ecommerce/checkout',
    className: 'ecommerce-application',
    component: lazy(() => import('../../views/apps/ecommerce/checkout')),
  },
  {
    path: '/apps/user/list',
    component: lazy(() => import('../../views/apps/user/list')),
  },
  {
    path: '/apps/user/view',
    exact: true,
    component: () => <Redirect to="/apps/user/view/1" />,
  },
  {
    path: '/apps/user/view/:id',
    component: lazy(() => import('../../views/apps/user/view')),
    meta: {
      navLink: '/apps/user/view',
    },
  },
  {
    path: '/apps/roles',
    component: lazy(() => import('../../views/apps/roles-permissions/roles')),
  },
  {
    path: '/apps/permissions',
    component: lazy(() =>
      import('../../views/apps/roles-permissions/permissions')
    ),
  },

  {
    path: '/admin/integrations',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Integrations/Integrations')
    ),
  },

  {
    path: '/admin/offline-tables',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/OfflineTables')),
  },

  {
    path: '/admin/integration/configuration/:integration/:customer_id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Integrations/Configurations')
    ),
  },

  {
    path: '/integrations',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Client/Integrations/Integrations')
    ),
  },

  {
    path: '/offline-tables',
    exact: true,
    appLayout: false,

    component: lazy(() =>
      import('../../pages/Client/Integrations/OfflineTables')
    ),
  },

  {
    path: '/integration/configuration/:integration/:customer_id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Client/Integrations/Configurations')
    ),
  },

  {
    path: '/admin/myFreightQuotes',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/MyFreightQuotes')
    ),
  },

  {
    path: '/myFreightQuotes',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Registration/MyFreightQuotes')
    ),
  },

  {
    path: '/admin/fulfillment/goods',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Goods')),
  },
  {
    path: '/admin/fulfillment/goods/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Goods/Form')),
  },
  {
    path: '/admin/fulfillment/goods/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Goods/Form')),
  },
  {
    path: '/admin/fulfillment/stock',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Stock')),
  },
  {
    path: '/admin/fulfillment/packaging',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Packing')),
  },
  {
    path: '/admin/fulfillment/packaging/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Packing/Form')),
  },
  {
    path: '/admin/fulfillment/packaging/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/Packing/Form')),
  },
  {
    path: '/admin/fulfillment/packaging-model',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/PackingModel')),
  },
  {
    path: '/admin/fulfillment/packaging-model/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Fulfillment/PackingModel/Form')
    ),
  },
  {
    path: '/admin/fulfillment/packaging-model/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Fulfillment/PackingModel/Form')
    ),
  },
  {
    path: '/admin/fulfillment/packaging-type',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() => import('../../pages/Admin/Fulfillment/PackingType')),
  },
  {
    path: '/admin/fulfillment/packaging-type/add',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Fulfillment/PackingType/Form')
    ),
  },
  {
    path: '/admin/fulfillment/packaging-type/:id',
    exact: true,
    appLayout: true,
    // className: 'email-application',
    component: lazy(() =>
      import('../../pages/Admin/Fulfillment/PackingType/Form')
    ),
  },
];

export default AppRoutes;
