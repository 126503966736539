import React, { useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserList } from '@src/components/UserList';

function CheckUser({
  permitedType,
  permitedValues,
  children,
  faturedVisible = true,
  emptyTag = 'Fragment',
  is100Percent = false,
}) {
  const userList = UserList();
  const usertype = useSelector((state) => state.otimize.usertype);
  const usercustomer = useSelector((state) => state.otimize.usercustomer);

  const [permited, setPermited] = useState(false);

  function checkUser() {
    //Se faturedVisible for false, usuários do tipo faturado não poderão visualizar
    if (faturedVisible === false) {
      if (usercustomer) {
        if (usercustomer.tip_faturamento === '2') {
          return;
        }
      }
    }

    for (let i = 0; i < userList.length; i++) {
      if (usertype === `${userList[i].value}`) {
        // UserType Existe

        if (`${userList[i].type}` === permitedType) {
          // UserType permitido

          if (typeof permitedValues !== 'undefined') {
            for (let j = 0; j < permitedValues.length; j++) {
              // Verifica se os valores passados como parametro são permitidos

              if (userList[i].value === permitedValues[j]) {
                setPermited(true);
                return;
              }
            }
            return;
          }
          // Se permitedValues for Null, é permitido todos os usuários do tipo
          setPermited(true);
          return;
        }
      }
    }
  }

  useEffect(() => {
    checkUser();
  }, [usercustomer]);

  if (permited === false) {
    if (emptyTag === 'div') {
      return <div />;
    } else {
      return <Fragment />;
    }
  }

  return(
    is100Percent? <div className='w-100'>{children}</div>
    : <Fragment>{children}</Fragment>
  )
  ;
}

export default CheckUser;
