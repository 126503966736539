const INITIAL_STATE = {
  sendgoodslist: [
    {
      items: [
        {
          quantidade: 1,
          altura: null,
          largura: null,
          comprimento: null,
          peso: null,
        },
      ],

      originCep: null,
      id: 0,
      ar: false,
      mp: false,
      isCompany: false,
      carrierId: null,
      carrierName: null,
      carrierServiceCode: null,
      logo: null,
      carrierService: null,
      carrierServiceId: null,
      carrierTerm: null,
      carrierMinDate: null,
      carrierMaxDate: null,
      carrierPrice: null,
      commodityValue: '0,01',
      destinationZip: null,
      destinationCity: null,
      destinationBairro: null,
      diameter: null,
      receiverName: null,
      receiverPhone: null,
      receiverEmail: null,
      receiverAddress: null,
      receiverNumber: null,
      receiverComplement: null,
      receiverCPF_CNPJ: null,
      emitCnpj: null,
      receiverIncricaoEstatual: null,
      receiverObs: null,
      receiverInvoiceKey: null,
      receiverNum: null,
      nfSerialNumber: null,
      receiverReminder: null,
      xml: null,
      xmlToSend: null,
      hash: null,
      state: null,
      instance: null,
      is_xml_required: false,
      fileType: 'xml',
      declaracao_id: null,
      is_declaracao_sended: false,
    },
  ],
  sendgoodstotal: 0,
  sendgoodsorigincep: null,
  sendgoodsstep: 0,

  savepassword: false,
  loading: null,
  token: null,
  userid: null,
  username: null,
  userlastname: null,
  useremail: null,
  usertype: null,
  useractive: null,
  usercustomerid: null,
  userterms: null,
  usercustomer: null,

  backgroundColor: 'sidebar-text-light',
  // backgroundColor: 'bg-royal sidebar-text-light',
  // headerBackgroundColor: 'bg-royal header-text-light',
  headerBackgroundColor: 'header-text-light',
  enableMobileMenuSmall: '',
  enableBackgroundImage: true,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: 'white',
  backgroundImage: '',
  backgroundImageOpacity: 'opacity-06',
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: false,
  pagevw: '80vw',
  balance: 0,
  firstReadHelp: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_READ_HELP':
      return {
        ...state,
        firstReadHelp: action.firstReadHelp,
      };

    case 'SET_INSTANCE':
      return {
        ...state,
        instance: action.instance,
      };

    case 'SET_BALANCE':
      return {
        ...state,
        balance: action.balance,
      };

    case 'SET_SEND_GOODS_STEP':
      return {
        ...state,
        sendgoodsstep: action.sendgoodsstep,
      };

    case 'SET_PAGEVW':
      return {
        ...state,
        pagevw: action.pagevw,
      };

    case 'SET_SEND_GOODS_CEP':
      return {
        ...state,
        sendgoodsorigincep: action.sendgoodsorigincep,
      };

    case 'SET_SEND_GOODS_TOTAL':
      return {
        ...state,
        sendgoodstotal: action.sendgoodstotal,
      };

    case 'SET_SEND_GOODS_LIST':
      return {
        ...state,
        sendgoodslist: action.sendgoodslist,
      };

    case 'SET_STEP_SAVE_PASSWORD':
      return {
        ...state,
        savepassword: action.savepassword,
      };

    case 'SET_STEP_STATUS':
      return {
        ...state,
        stepstatus: action.stepstatus,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };

    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token,
      };

    case 'SET_SESSION':
      return {
        ...state,
        userid: action.userid,
        username: action.username,
        userlastname: action.userlastname,
        useremail: action.useremail,
        usertype: action.usertype,
        useractive: action.useractive,
        usercustomerid: action.usercustomerid,
        userterms: action.userterms,
        usercustomer: action.usercustomer,
      };

    case 'SET_ENABLE_BACKGROUND_IMAGE':
      return {
        ...state,
        enableBackgroundImage: action.enableBackgroundImage,
      };

    case 'SET_ENABLE_FIXED_HEADER':
      return {
        ...state,
        enableFixedHeader: action.enableFixedHeader,
      };

    case 'SET_ENABLE_HEADER_SHADOW':
      return {
        ...state,
        enableHeaderShadow: action.enableHeaderShadow,
      };

    case 'SET_ENABLE_SIDEBAR_SHADOW':
      return {
        ...state,
        enableSidebarShadow: action.enableSidebarShadow,
      };

    case 'SET_ENABLE_PAGETITLE_ICON':
      return {
        ...state,
        enablePageTitleIcon: action.enablePageTitleIcon,
      };

    case 'SET_ENABLE_PAGETITLE_SUBHEADING':
      return {
        ...state,
        enablePageTitleSubheading: action.enablePageTitleSubheading,
      };

    case 'SET_ENABLE_PAGE_TABS_ALT':
      return {
        ...state,
        enablePageTabsAlt: action.enablePageTabsAlt,
      };

    case 'SET_ENABLE_FIXED_SIDEBAR':
      return {
        ...state,
        enableFixedSidebar: action.enableFixedSidebar,
      };

    case 'SET_ENABLE_MOBILE_MENU':
      return {
        ...state,
        enableMobileMenu: action.enableMobileMenu,
      };

    case 'SET_ENABLE_MOBILE_MENU_SMALL':
      return {
        ...state,
        enableMobileMenuSmall: action.enableMobileMenuSmall,
      };

    case 'SET_ENABLE_CLOSED_SIDEBAR':
      return {
        ...state,
        enableClosedSidebar: action.enableClosedSidebar,
      };

    case 'SET_ENABLE_FIXED_FOOTER':
      return {
        ...state,
        enableFixedFooter: action.enableFixedFooter,
      };

    case 'SET_BACKGROUND_COLOR':
      return {
        ...state,
        backgroundColor: action.backgroundColor,
      };

    case 'SET_HEADER_BACKGROUND_COLOR':
      return {
        ...state,
        headerBackgroundColor: action.headerBackgroundColor,
      };

    case 'SET_COLOR_SCHEME':
      return {
        ...state,
        colorScheme: action.colorScheme,
      };

    case 'SET_BACKGROUND_IMAGE':
      return {
        ...state,
        backgroundImage: action.backgroundImage,
      };

    case 'SET_BACKGROUND_IMAGE_OPACITY':
      return {
        ...state,
        backgroundImageOpacity: action.backgroundImageOpacity,
      };

    default:
      return state;
  }
}
