// ** Icons Import
import { Paperclip } from 'react-feather'

export default [
  {
    id: 'envioXml',
    title: 'Envio de XML',
    icon: <Paperclip />,
    children: [
      {
        id: 'envioXmlMercadorias',
        title: 'Envio de XML de Mercadorias',
        icon: <Paperclip />,
        navLink: '/admin/ExportXML'
      },
      {
        id: 'importXmlClientes',
        title: 'Importar XML de Clientes',
        icon: <Paperclip />,
        navLink: '/admin/ImportXML'
      },
    ]
  }
]
