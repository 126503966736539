// ** Icons Import
import { Box, Edit2, ExternalLink, Truck, Map } from 'react-feather';

export default [
  {
    id: 'coleta',
    title: 'Coleta',
    icon: <Box />,
    children: [
      {
        id: 'entradaMercadoria',
        title: 'Entrada de Mercadoria',
        icon: <Edit2 size={20} />,
        navLink: '/admin/collect/goodsreceipt',
      },
      {
        id: 'listaRetirada',
        title: 'Lista de Retirada',
        icon: <ExternalLink size={20} />,
        navLink: '/admin/collect/withdrawlist',
      },
      {
        id: 'retiradaTransportadora',
        title: 'Manifesto',
        icon: <Truck />,
        navLink: '/admin/collect/withdrawlistcarrier',
      },
      {
        id: 'códigoRastreio',
        title: 'Rastreamento',
        icon: <Map />,
        navLink: '/admin/collect/trackingcode',
      },
    ],
  },
];
