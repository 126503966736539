// ** Icons Import
import { Layers, Users, Truck, MapPin, FileText, Link } from 'react-feather'

export default [
  {
    id: 'cadastrosBasicos',
    title: 'Cadastros',
    icon: <Layers />,
    children: [
      {
        id: 'transportadoras',
        title: 'Transportadoras',
        icon: <Truck />,
        navLink: '/admin/registration/carriers'
      },
      {
        id: 'clientes',
        title: 'Clientes',
        icon: <Users />,
        navLink: '/admin/registration/customers'
      },
      {
        id: 'representantes',
        title: 'Representantes',
        icon: <Users />,
        navLink: '/admin/registration/representante'
      },
      {
        id: 'faixaCeps',
        title: "Faixas de CEP",
        icon: <MapPin />,
        navLink: '/admin/registration/cities'
      },
      {
        id: 'mappingStatus',
        title: 'Mapear status de rastreio',
        icon: <FileText />,
        navLink: '/admin/registration/mappingstatus'
      },
    ]
  }
]
