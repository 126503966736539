import React, { Fragment, useState, useEffect } from 'react';

import { Form, Row, message, Col } from 'antd';
import { Button, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import InputRs from '@src/components/Input/Rs';
import { validateMessages } from '@src/components/ValidateMessages';
import { Link } from 'react-router-dom';
import { stringRealToFloatDolar } from '@src/components/Convert/StringRealToFloatDolar';

import { useSelector } from 'react-redux';
import api from '@src/services/envieja-api';
import CatchErrors from '@src/components/CatchErrors';
import { stringDolarToStringReal } from '@src/components/Convert/StringDolarToStringReal';

// import { Container } from './styles';

function AddValue(props) {
  const { setIsModalVisible } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const usercustomerid = useSelector((state) => state.otimize.usercustomerid);

  const [form] = Form.useForm();

  async function onFinish(values) {
    let { value } = values;
    value = stringRealToFloatDolar(value);

    setLoading(true);

    await api
      .post('payments/pagsegurorequest', {
        value,
        customer_id: usercustomerid,
        status: 1,
      })

      .then((response) => {
        let apiCode;

        if (response.data && response.data.checkout) {
          apiCode = response.data.checkout.code;

          setCode(apiCode);

          window.open(
            `${process.env.REACT_APP_PAGSEGURO_URI}/v2/checkout/payment.html?code=${apiCode}`,
            '_blank'
          );
        } else {
          if (response.data.error) {
            message.error(CatchErrors(response.data.error));

            setErrorMessage(
              <Fragment>
                <div>
                  Certifique-se que o nome do responsável é válido, por favor
                  preencha corretamente o nome completo do responsável em{' '}
                  <Link to="/company">Dados da empresa</Link>
                </div>
                <br />
                {response.data.error.message && (
                  <div>
                    Erro retornado do pagseguro: {response.data.error.message}{' '}
                  </div>
                )}
              </Fragment>
            );

            return;
          }

          message.error('Requisição não encontrada');
        }
      })

      .then(() => {
        setLoading(false);
      })

      .catch((error) => {
        console.error(error);
        message.error(CatchErrors(error));
        setLoading(false);
      });
  }

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Row>
            {/*

              
            {process.env.REACT_APP_PAG_SEGURO_URI}


            <Alert color="info" style={{ padding: '16px' }}>
              <b style={{ fontSize: '24px', textAlign: 'center' }}>
                Informação
              </b>
              <p>
                Insira o valor abaixo e aguarde que logo você será redirecionado
                para o <b>PagSeguro</b> onde poderá concluir sua transação.
              </p>
            </Alert>
              */}

            <Alert color="danger" style={{ padding: '16px' }}>
              <b style={{ fontSize: '24px', textAlign: 'center' }}>Atenção</b>
              <p>
                <b>Caro cliente! </b> Estamos passando por uma reestruturação em
                nosso sistema, por isso <b>não é possível adicionar crédito</b>,
                para maiores detalhes entrar em contato via <b>WhatsApp</b>,
                Obrigado pela colaboração de todos!
              </p>
            </Alert>
          </Row>

          <br />

          {errorMessage && (
            <Fragment>
              <Alert color="error">
                <Fragment>{errorMessage}</Fragment>
              </Alert>
              <br />
            </Fragment>
          )}

          {/* 
          <Form
            validateMessages={validateMessages}
            form={form}
            layout="vertical"
            id="balanceForm"
            onFinish={onFinish}
          >
            <Row>
              <Form.Item
                name="value"
                label="Valor"
                rules={[
                  {
                    required: true,
                  },
                  () => ({
                    async validator(_, value) {
                      const minValue = 50;

                      const formatedValue = stringRealToFloatDolar(value);
                      const formatedMinValue =
                        stringDolarToStringReal(minValue);

                      if (formatedValue < minValue) {
                        return Promise.reject(
                          new Error(
                            `O valor mínimo permitido é de ${formatedMinValue}`
                          )
                        );
                      }

                      return true;
                    },
                  }),
                ]}
              >
                <InputRs />
              </Form.Item>
              
              <Form.Item label={' '}>
                <Button loading={loading} color="primary">
                  Adicionar fundos
                </Button>
              </Form.Item>
            </Row>
            <br />

            {code && (
              <Row>
                <Alert color="warning">
                  <Fragment>
                    Caso a janela não abra automáticamente, clique{' '}
                    <a
                      href={`${process.env.REACT_APP_PAGSEGURO_URI}/v2/checkout/payment.html?code=${code}`}
                    >
                      aqui
                    </a>{' '}
                    para concluir a transação
                  </Fragment>
                </Alert>
              </Row>
            )}
          </Form>

          <br />
          */}

          <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button onClick={() => setIsModalVisible(false)} color="danger">
              Fechar
            </Button>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default AddValue;
