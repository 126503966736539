import React, { Fragment } from 'react';

import { Spin, Space } from 'antd';

function LoadingPage() {
  return (
    <Fragment>
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    </Fragment>
  );
}

export default LoadingPage;
