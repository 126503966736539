import React, { useEffect, useState, Fragment } from 'react';
import { Row, Card, message, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CatchErrors from '@src/components/CatchErrors';
import api from '@src/services/envieja-api';
import { stringDolarToStringReal } from '@src/components/Convert/StringDolarToStringReal';

function WalletComponent(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const usercustomer = useSelector((state) => state.otimize.usercustomer);
  const balance = useSelector((state) => state.otimize.balance);

  function loadData() {
    setLoading(true);
    api
      .get('wallets', {
        params: {
          params: [
            {
              field: 'customer_id',
              value: usercustomer && usercustomer.id,
              op: '=',
            },
          ],
        },
      })
      .then((response) => {
        const { amount } = response.data.data[0];
        const data = response.data.data[0];
        let amountToFloat = 0;

        if (amount) {
          amountToFloat = parseFloat(amount, 10);
        } else {
          data.amount = '0';
        }

        setData(data);
        setCount(count + 1);
        dispatch({
          type: 'SET_BALANCE',
          balance: amountToFloat,
        });
      })

      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        CatchErrors(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, [balance]);

  return (
    <Row
      id="wallet-component-header"
      className="wallet-component-header"
      onClick={() => history.push('/financial/invoices')}
      // onClick={loadData}
    >
      <Col>
        <div
          style={{
            display: 'flex',
            borderStyle: 'none',
            background: 'none',
            color: '#FFF',

            height: '100%',
          }}
          bodyStyle={{ padding: 0, display: 'flex', alignItems: 'center' }}
        >
          <b
            style={{
              dysplay: 'flex',
              color: '#ff9f43',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            <i className="pe-7s-piggy" style={{ paddingRight: '0.3rem' }} />
            Carteira:
          </b>
        </div>
      </Col>
      <Col>
        <div
          style={{
            display: 'flex',
            color: '#ff9f43',
            borderStyle: 'none',
            background: 'none',

            height: '100%',
          }}
          bodyStyle={{ padding: 0, display: 'flex', alignItems: 'center' }}
        >
          {loading ? (
            <b style={{ padding: '10px' }}>Carregando...</b>
          ) : (
            <b style={{ padding: '10px' }}>
              {data && data.amount && (
                <Fragment>{stringDolarToStringReal(data.amount)}</Fragment>
              )}
            </b>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default WalletComponent;
