// ** React Imports
import { Link } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// ** Custom Components
import Avatar from '@components/avatar';

// ** Utils
import { isUserLoggedIn } from '@utils';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';

// ** Third Party Components
import { HelpCircle, Power } from 'react-feather';

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg';
import { UserList } from '@src/components/UserList';
import ClearSendGoodsList from '@src/components/ClearSendGoodsList';
import CheckUser from '@src/components/CheckUser';
import { Col, Dropdown, Popover, Row } from 'antd';

const UserDropdown = () => {
  const [clearSendGoods, setClearSendGoods] = useState(false);
  const usertype = useSelector((state) => state.otimize.usertype);

  const username = useSelector((state) => state.otimize.username);
  const userlastname = useSelector((state) => state.otimize.userlastname);

  const [clearSessionState, setClearSessionState] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const userList = UserList();

  async function clearSession() {
    dispatch({
      type: 'SET_SESSION',
      userid: null,
      username: null,
      userlastname: null,
      useremail: null,
      usertype: null,
      useractive: null,
    });

    dispatch({
      type: 'SET_TOKEN',
      token: null,
    });
    history.push('/login');
  }

  const stateNum = Math.floor(Math.random() * 6),
    states = [
      'light-success',
      'light-danger',
      'light-warning',
      'light-info',
      'light-primary',
      'light-secondary',
    ],
    color = states[stateNum];

  useEffect(() => {
    if (clearSessionState === true) {
      clearSession();
    }
  }, [clearSessionState]);

  // ** State
  const [userData, setUserData] = useState(null);

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')));
    }
  }, []);

  function goToHelp() {
    let button = document.getElementById('tour-button');

    if (button) {
      button.click();
    }
  }

  const items = [
    {
      key: '1',
      label: (
        <span
          onClick={() => clearSession()}
          className="align-middle"
          style={{
            display: 'block',
            width: '100%',
          }}
        >
          Logout
        </span>
      ),
    },
  ];

  return (
    <Row>
      <ClearSendGoodsList clear={clearSendGoods} setClear={setClearSendGoods} />

      <CheckUser permitedType={'client'}>
        <HelpCircle
          onClick={goToHelp}
          style={{
            cursor: 'pointer',
          }}
          id="help-button-tour"
        />
      </CheckUser>
      <Dropdown
        menu={{
          items: items,
        }}
        style={{
          cursor: 'pointer',
        }}
        placement="bottom"
        arrow
      >
        <Row
          style={{
            cursor: 'pointer',
          }}
        >
          <Row>
            <Col
              style={{
                textAlign: 'end',
              }}
              span={24}
            >
              <span className="user-name fw-bold">
                {username} {userlastname}
              </span>
            </Col>
            <Col
              style={{
                textAlign: 'end',
              }}
              span={24}
            >
              <span
                style={{
                  fontSize: 'x-small',
                }}
                className="user-status"
              >
                {userList.map((userType) => {
                  if (usertype === `${userType.value}`) {
                    return <Fragment>{userType.name}</Fragment>;
                  }

                  return <Fragment />;
                })}
              </span>
            </Col>
          </Row>
          <Avatar
            style={{
              marginLeft: '12px',
            }}
            imgHeight="40"
            imgWidth="40"
            status="online"
            color={color || 'primary'}
            className="me-1"
            content={
              username && username.toUpperCase() ? username.toUpperCase() : ''
            }
            initials
          />
        </Row>
      </Dropdown>
    </Row>
  );
};

export default UserDropdown;
