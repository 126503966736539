// ** Icons Import
import { Paperclip } from 'react-feather';

export default [
  {
    id: 'enviarXMLClient',
    title: 'Enviar XML',
    icon: <Paperclip />,
    children: [
      {
        id: 'importarXMLClient',
        title: 'Importar XML',
        icon: <Paperclip />,

        navLink: '/importXML',
      },
    ],
  },
];
