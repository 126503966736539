import React, { useEffect, Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// import { Container } from './styles';

function ClearSendGoodsList(props) {
  const { clear, setClear } = props;
  const sendgoodslist = useSelector((state) => state.otimize.sendgoodslist);
  const dispatch = useDispatch();

  function clearSendGoods() {
    const clearSendGoodList = sendgoodslist;

    clearSendGoodList.length = 1;
    clearSendGoodList[0] = {
      items: [
        {
          quantidade: 1,
          altura: null,
          largura: null,
          comprimento: null,
          peso: null,
        },
      ],

      originCep: null,
      id: 0,
      ar: false,
      mp: false,
      isCompany: false,
      carrierId: null,
      carrierName: null,
      carrierService: null,
      carrierServiceId: null,
      carrierTerm: null,
      carrierPrice: null,
      commodityValue: null,
      destinationZip: null,
      height: null,
      length: null,
      weight: null,
      width: null,
      diameter: null,
      emitCnpj: null,
      receiverName: null,
      receiverPhone: null,
      receiverEmail: null,
      receiverAddress: null,
      receiverNumber: null,
      receiverComplement: null,
      receiverCPF_CNPJ: null,
      receiverIncricaoEstatual: null,
      receiverObs: null,
      receiverInvoiceKey: null,
      receiverNum: null,
      nfSerialNumber: null,
      destinationBairro: null,
      receiverReminder: null,
      xml: null,
      is_xml_required: false,
    };

    dispatch({
      type: 'SET_SEND_GOODS_LIST',
      sendgoodslist: clearSendGoodList,
    });

    dispatch({
      type: 'SET_SEND_GOODS_STEP',
      sendgoodsstep: 0,
    });

    setClear(false);
  }

  useEffect(() => {
    if (clear === true) {
      clearSendGoods();
    }
  }, [clear]);

  return <Fragment />;
}

export default ClearSendGoodsList;
