// ** Icons Import
import {
  DollarSign,
  UserPlus,
  PieChart,
  Table,
  MessageCircle,
} from 'react-feather';

export default [
  {
    id: 'administração',
    title: 'Administração',
    icon: <PieChart />,
    children: [
      {
        id: 'cadastroComissão',
        title: 'Cadastro de Comissão',
        icon: <DollarSign />,
        navLink: '/admin/administration/commission',
      },
      {
        id: 'usuarios',
        title: 'Usuários',
        icon: <UserPlus />,
        navLink: '/admin/administration/users',
      },
      {
        id: 'atualizarTabelasDeFrete',
        title: 'Atualizar tabelas de frete',
        icon: <Table />,
        navLink: '/admin/administration/update-freight',
      },
      {
        id: 'whatsappLog',
        title: 'Whatsapp Logs',
        icon: <MessageCircle />,
        navLink: '/admin/administration/whatsapp-logs',
      },
    ],
  },
];
