// ** Icons Import
import { Cpu, Link } from 'react-feather';

export default [
  {
    id: 'linksCotacao',
    title: 'Links de cotação',
    icon: <Link />,
    children: [
      {
        id: 'meusLinks',
        title: 'Meus Links de cotação',
        icon: <Link size={20} />,
        navLink: 'myFreightQuotes',
      },
    ],
  },
];
