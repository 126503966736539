// ** Icons Import
import { CreditCard, FileText } from 'react-feather';

export default [
  {
    id: 'financeiroClient',
    title: 'Financeiro',
    icon: <CreditCard />,
    children: [
      {
        id: 'carteiraClient',
        title: 'Carteira',
        icon: <CreditCard />,

        navLink: '/financial/invoices',
      },
      {
        id: 'coletoClient',
        title: 'Boleto',
        icon: <FileText />,

        navLink: '/boletoClient',
      },
    ],
  },
];
