// ** React Imports
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux';
import { handleMenuHidden, handleContentWidth } from '@store/layout';

// ** Third Party Components
import classnames from 'classnames';
import { ArrowUp, HelpCircle } from 'react-feather';

// ** Reactstrap Imports
import { Navbar, NavItem, Button } from 'reactstrap';

// ** Configs
import themeConfig from '@configs/themeConfig';
import otimizeLogotipo from '../../assets/images/pages/otimizeLogotipo.svg';

// ** Custom Components
import Customizer from '@components/customizer';
import ScrollToTop from '@components/scrolltop';
import NavbarComponent from './components/navbar';
// import FooterComponent from './components/footer';
import MenuComponent from './components/menu/horizontal-menu';

// ** Custom Hooks
import { useRTL } from '@hooks/useRTL';
import { useSkin } from '@hooks/useSkin';
import { useNavbarType } from '@hooks/useNavbarType';
// import { useFooterType } from '@hooks/useFooterType';
import { useNavbarColor } from '@hooks/useNavbarColor';

// ** Styles
import '@styles/base/core/menu/menu-types/horizontal-menu.scss';
import WalletComponent from '@src/Layout/AppHeader/Components/Wallet';
import CheckUser from '@src/components/CheckUser';
import ButtonAddCredit from '@src/components/Button/AddCredit';

const HorizontalLayout = (props) => {
  const [dynamicMenuState, setDynamicMenuState] = useState([]);

  const usercustomer = useSelector((state) => state.otimize.usercustomer);

  // ** Props
  const {
    children,
    navbar,
    menuData,
    footer,
    menu,
    currentActiveItem,
    routerProps,
    setLastLayout,
  } = props;

  // ** Hooks
  const { skin, setSkin } = useSkin();
  const history = useHistory();
  const [isRtl, setIsRtl] = useRTL();
  const { navbarType, setNavbarType } = useNavbarType();
  // const { footerType, setFooterType } = useFooterType();
  const { navbarColor, setNavbarColor } = useNavbarColor();

  // ** States
  const [isMounted, setIsMounted] = useState(false);
  const [navbarScrolled, setNavbarScrolled] = useState(false);

  // ** Store Vars
  const dispatch = useDispatch();
  const layoutStore = useSelector((state) => state.layout);
  const usertype = useSelector((state) => state.otimize.usertype);

  // ** Vars
  const contentWidth = layoutStore.contentWidth;
  const isHidden = layoutStore.menuHidden;

  // ** Handles Content Width
  const setContentWidth = (val) => dispatch(handleContentWidth(val));

  // ** Handles Content Width
  const setIsHidden = (val) => dispatch(handleMenuHidden(val));

  // ** UseEffect Cleanup
  const cleanup = () => {
    setIsMounted(false);
    setNavbarScrolled(false);
  };

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true);
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 65 && navbarScrolled === false) {
        setNavbarScrolled(true);
      }
      if (window.pageYOffset < 65) {
        setNavbarScrolled(false);
      }
    });
    return () => cleanup();
  }, []);

  function dynamicMenu() {
    let newData = [];

    for (let index = 0; index < menuData.length; index++) {
      switch (usertype) {
        //Super admin otimize
        case '0':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'administração' ||
            menuData[index].id === 'cadastrosBasicos' ||
            menuData[index].id === 'financeiro' ||
            menuData[index].id === 'envioXml' ||
            menuData[index].id === 'ajuda' ||
            menuData[index].id === 'coleta' ||
            menuData[index].id === 'integracoes' ||
            menuData[index].id === 'fulfillment'
          ) {
            newData.push(menuData[index]);
          }
          break;

        //Super Admin Cliente
        case '1':
          if (
            menuData[index].id === 'dashboardClient' ||
            menuData[index].id === 'operationsClient' ||
            menuData[index].id === 'financeiroClient' ||
            menuData[index].id === 'cadastroClient' ||
            menuData[index].id === 'enviarXMLClient' ||
            menuData[index].id === 'ajudaClient' ||
            menuData[index].id === 'coletaClient' ||
            // menuData[index].id === 'linksCotacao' ||
            menuData[index].id === 'integracoesClient'
          ) {
            newData.push(menuData[index]);
          }

          break;

        //Financeiro cliente
        case '2':
          if (
            menuData[index].id === 'dashboardClient' ||
            menuData[index].id === 'operationsClient' ||
            menuData[index].id === 'coletaClient' ||
            menuData[index].id === 'enviarXMLClient' ||
            menuData[index].id === 'financeiroClient' ||
            menuData[index].id === 'cadastroClient' ||
            menuData[index].id === 'ajudaClient'
          ) {
            newData.push(menuData[index]);
          }

          break;

        //Financeiro otimize
        case '3':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'financeiro' ||
            menuData[index].id === 'cadastrosBasicos'
          ) {
            if (menuData[index].id === 'cadastrosBasicos') {
              for (let j = 0; j < menuData[index].children.length; j++) {
                if (
                  menuData[index].children[j].id === 'transportadoras' ||
                  menuData[index].children[j].id === 'representantes'
                ) {
                  menuData[index].children.splice(j, 1);
                }
              }
            }
            newData.push(menuData[index]);
          }
          break;

        //Logistica otimize
        case '4':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'administração' ||
            menuData[index].id === 'envioXml' ||
            menuData[index].id === 'ajuda' ||
            menuData[index].id === 'coleta' ||
            menuData[index].id === 'fulfillment'
          ) {
            newData.push(menuData[index]);
          }
          break;

        //Suporte otimize
        case '5':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'ajuda'
          ) {
            newData.push(menuData[index]);
          }
          break;

        //Representante otimize
        case '6':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'envioXml' ||
            menuData[index].id === 'ajuda' ||
            menuData[index].id === 'cadastrosBasicos' ||
            menuData[index].id === 'coleta'
          ) {
            if (menuData[index].id === 'cadastrosBasicos') {
              for (let j = 0; j < menuData[index].children.length; j++) {
                if (
                  menuData[index].children[j].id === 'transportadoras' ||
                  menuData[index].children[j].id === 'representantes'
                ) {
                  menuData[index].children.splice(j, 1);
                }
              }
            }
            newData.push(menuData[index]);
          }
          break;

        //Logistica cliente
        case '7':
          if (
            menuData[index].id === 'dashboardClient' ||
            menuData[index].id === 'operationsClient' ||
            menuData[index].id === 'enviarXMLClient' ||
            menuData[index].id === 'ajudaClient' ||
            menuData[index].id === 'coletaClient'
          ) {
            newData.push(menuData[index]);
          }

          break;

        //Comercial otimize
        case '8':
          if (
            menuData[index].id === 'dashboard' ||
            menuData[index].id === 'envioXml' ||
            menuData[index].id === 'coleta'
          ) {
            newData.push(menuData[index]);
          }
          break;

        default:
          break;
      }
    }

    setDynamicMenuState(newData);
  }

  useEffect(() => {
    if (usertype) {
      dynamicMenu();
    }
  }, [menuData, usertype]);

  // ** Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden',
  };

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static',
  };

  const navbarClasses = {
    floating:
      contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
    sticky: 'fixed-top',
  };

  const bgColorCondition =
    navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white';

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={classnames(
        `wrapper horizontal-layout horizontal-menu ${
          navbarWrapperClasses[navbarType] || 'navbar-floating'
        }`
      )}
      {...(isHidden ? { 'data-col': '1-column' } : {})}
    >
      <Navbar
        expand="lg"
        container={false}
        className={classnames(
          'header-navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center',
          {
            'navbar-scrolled': navbarScrolled,
          }
        )}
      >
        <div
          style={{
            width: '20px',
          }}
        />

        <CheckUser permitedType={'admin'}>
          <div style={{ margin: '12px' }}>
            <NavItem style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => history.push('/admin/calculator/shipping')}
                style={{ width: '200px' }}
                color="primary"
              >
                Calcular frete
              </Button>
            </NavItem>
          </div>
        </CheckUser>

        <CheckUser permitedType={'client'} permitedValues={[1, 2]}>
          <div style={{ margin: '12px' }}>
            <NavItem style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                id="botao-calcular-frete"
                onClick={() => history.push('/send-goods')}
                style={{ width: '200px' }}
                color="primary"
              >
                Calcular frete
              </Button>
            </NavItem>
          </div>
        </CheckUser>

        <CheckUser
          permitedType={'client'}
          permitedValues={[1, 2]}
          faturedVisible={false}
        >
          <NavItem style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonAddCredit
              style={{
                width: '200px',
              }}
            >
              Adicionar crédito
            </ButtonAddCredit>
          </NavItem>
        </CheckUser>

        {!navbar && (
          <div className="navbar-header d-xl-block d-none">
            <ul className="nav navbar-nav">
              <CheckUser permitedType="admin">
                <NavItem style={{}}>
                  <Link to={`/admin/dashboard`} className="navbar-brand">
                    <img
                      src={otimizeLogotipo}
                      width="125"
                      height="100"
                      alt="logo"
                    />
                    {/* <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2> */}
                  </Link>
                </NavItem>
              </CheckUser>

              <CheckUser permitedType="client">
                <NavItem style={{ marginRight: '12px' }}>
                  <Link to={`/dashboard`} className="navbar-brand">
                    <img
                      src={otimizeLogotipo}
                      width="125"
                      height="100"
                      alt="logo"
                    />
                    {/* <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2> */}
                  </Link>
                </NavItem>
              </CheckUser>

              <CheckUser permitedType={'client'} permitedValues={[1, 2]}>
                <NavItem style={{ display: 'flex', alignItems: 'center' }}>
                  <WalletComponent />
                </NavItem>
              </CheckUser>
            </ul>
          </div>
        )}

        <div className="navbar-container d-flex content">
          {navbar ? navbar : <NavbarComponent skin={skin} setSkin={setSkin} />}
        </div>
      </Navbar>
      {!isHidden ? (
        <div className="horizontal-menu-wrapper">
          <Navbar
            tag="div"
            expand="sm"
            className={classnames(
              `header-navbar navbar-horizontal navbar-shadow menu-border`,
              {
                [navbarClasses[navbarType]]: navbarType !== 'static',
                'floating-nav':
                  (!navbarClasses[navbarType] && navbarType !== 'static') ||
                  navbarType === 'floating',
              }
            )}
          >
            <MenuComponent
              menuData={dynamicMenuState}
              routerProps={routerProps}
              currentActiveItem={currentActiveItem}
            />
          </Navbar>
        </div>
      ) : null}

      {children}
      {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={skin}
          setSkin={setSkin}
          // footerType={footerType}
          // setFooterType={setFooterType}
          navbarType={navbarType}
          setNavbarType={setNavbarType}
          navbarColor={navbarColor}
          setNavbarColor={setNavbarColor}
          isRtl={isRtl}
          setIsRtl={setIsRtl}
          layout={props.layout}
          setLastLayout={setLastLayout}
          setLayout={props.setLayout}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          contentWidth={contentWidth}
          setContentWidth={setContentWidth}
          transition={props.transition}
          setTransition={props.setTransition}
          themeConfig={themeConfig}
        />
      ) : null}

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  );
};
export default HorizontalLayout;
