// ** Navigation imports
import administração from './administração';
import envioXml from './envioXml';
import coleta from './coleta';
import ajuda from './ajuda';
import dashboard from './dashboard';
import cadastrosBasicos from './cadastrosBasicos';
import financeiro from './financeiro';
import dashboardClient from './client/dashboardClient';
import operations from './client/operations';
import financeiroClient from './client/financeiroClient';
import cadastroClient from './client/cadastroClient';
import enviarXMLClient from './client/enviarXML';
import ajudaClient from './client/ajudaClient';
import coletaClient from './client/coletaClient';
import integracoesClient from './client/integracoes';
import integracoes from './integracoes';
import linksCotacao from './linksCotacao';
import fulfillment from './fulfillment';

// ** Merge & Export
export default [
  ...dashboard,
  ...administração,
  ...cadastrosBasicos,
  ...financeiro,
  ...envioXml,
  ...ajuda,
  ...coleta,
  ...integracoes,
  ...dashboardClient,
  ...operations,
  ...financeiroClient,
  ...cadastroClient,
  ...enviarXMLClient,
  ...ajudaClient,
  ...coletaClient,
  ...linksCotacao,
  ...integracoesClient,
  ...fulfillment,
];
