/*
Ao acrescentar tipo de usuários:

 - Tratar na pagina de login
 - Tratar no arquivo de routes

*/

export const UserList = () => [
  {
    value: 0,
    name: 'Super Admin',
    type: 'admin',
    ticket: 'Responsável',
  },

  {
    value: 1,
    name: 'Super Admin',
    type: 'client',
    ticket: 'Solicitante',
  },

  {
    value: 2,
    name: 'Financeiro',
    type: 'client',
    ticket: 'Solicitante',
  },

  {
    value: 3,
    name: 'Financeiro',
    type: 'admin',
    ticket: 'Responsável',
  },

  {
    value: 4,
    name: 'Logistica',
    type: 'admin',
    ticket: 'Responsável',
  },

  {
    value: 5,
    name: 'Suporte',
    type: 'admin',
    ticket: 'Responsável',
  },

  {
    value: 6,
    name: 'Representante',
    type: 'admin',
    ticket: 'Responsável',
  },

  {
    value: 7,
    name: 'Logistica',
    type: 'client',
    ticket: 'Solicitante',
  },

  {
    value: 8,
    name: 'Comercial',
    type: 'admin',
    ticket: 'Responsável',
  },
];
