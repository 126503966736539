// ** Icons Import
import { Map, Truck } from 'react-feather';

export default [
  {
    id: 'coletaClient',
    title: 'Coleta',
    icon: <Truck />,
    children: [
      {
        id: 'rastreamentoClient',
        title: 'Rastreamento',
        icon: <Map />,

        navLink: '/collect/tracking',
      },
    ],
  },
];
