// ** Icons Import
import { Phone } from 'react-feather'

export default [
  {
    id: 'ajuda',
    title: 'Ajuda',
    icon: <Phone />,
    children: [
      {
        id: 'chamados',
        title: 'Chamados',
        icon: <Phone />,
        navLink: '/admin/help/servicecall'
      },
    ]
  }
]
