import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'root',
      storage,
      whitelist: ['token', 'usercustomer', 'firstReadHelp'],
    },
    reducers
  );

  return persistedReducer;
};
