// ** Icons Import
import { Cpu, Table } from 'react-feather';

export default [
  {
    id: 'integracoesClient',
    title: 'Integrações',
    icon: <Cpu />,
    children: [
      {
        id: 'integracoes',
        title: 'Integrações',
        icon: <Cpu size={20} />,
        navLink: '/integrations',
      },
      {
        id: 'offlinetables',
        title: 'Tabelas offline',
        icon: <Table size={20} />,
        navLink: '/offline-tables',
      },
    ],
  },
];
