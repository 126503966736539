// ** Icons Import
import { DivideCircle, Briefcase, Send, Map, Link, ShoppingBag } from 'react-feather';

export default [
  {
    id: 'operationsClient',
    title: 'Operações',
    icon: <Briefcase />,
    children: [
      {
        id: 'calculadoraFreteClient',
        title: 'Calculadora de frete',
        icon: <DivideCircle />,

        navLink: '/send-goods',
      },
      {
        id: 'meusEnviosClient',
        title: 'Meus envios',
        icon: <Send />,

        navLink: '/shipments',
      },
      {
        id: 'rastreamentoClient',
        title: 'Rastreamento',
        icon: <Map />,

        navLink: '/operations/tracking',
      },
      {
        id: 'linksCotacao',
        title: 'Links de cotação',
        icon: <Link />,

        navLink: '/myFreightQuotes',
      },
      {
        id: 'meusPedidos',
        title: 'Meus Pedidos',
        icon: <ShoppingBag />,

        navLink: '/operations/myorders',
      },
    ],
  },
];
