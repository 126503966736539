// ** Icons Import
import { Paperclip, Phone } from 'react-feather';

export default [
  {
    id: 'ajudaClient',
    title: 'Ajuda',
    icon: <Phone />,
    children: [
      {
        id: 'chamadosClient',
        title: 'Chamados',
        icon: <Phone />,

        navLink: '/servicecall',
      },
    ],
  },
];
