// ** Core Layout Import
// !Do not remove the Layout import

//src/core/layouts
import Layout from '@layouts/HorizontalLayout';

// ** Menu Items Array
import navigation from '@src/navigation/horizontal';

const HorizontalLayout = (props) => {
  return (
    <Layout menuData={navigation} {...props}>
      {props.children}
    </Layout>
  );
};

export default HorizontalLayout;
